import {
  Add,
  Delete,
  Edit,
  MoreVert,
  MoreVertOutlined,
} from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { Reorder } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DeleteTopic,
  getOneSubject,
  sortchapters,
} from "../../../../api/services";
import DialogWrapper from "../../../../components/DialogWrapper/DialogWrapper";
import { Loader } from "../../../../components/loader";
import CreateChapter from "./CreateChapter";
import TopicForm from "./TopicForm";
import { SectionForm } from "./sectionForm";
var momentDurationFormatSetup = require("moment-duration-format");

momentDurationFormatSetup(moment);

export const SidePanel = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(["subjectById", id], getOneSubject);

  const queryClient = useQueryClient();

  const { mutate: sortChapters } = useMutation(sortchapters, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("subjectById");
      toast.success(res?.data?.message);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  // Initialize items state with an empty array
  const [items, setItems] = useState([]);

  // When data is fetched, update items state
  useEffect(() => {
    if (data && data?.data && data?.data?.chapters) {
      setItems(data?.data?.chapters);
    }
  }, [data]);

  useEffect(() => {
    sortChapters({ id: id, body: [] });
  }, []);

  const handleReorder = (newItems: any) => {
    if (JSON.stringify(newItems) !== JSON.stringify(items)) {
      setItems(newItems);
      const weekarray = newItems.map((item: any) => item._id);
      const body = { ids: weekarray };
      sortChapters({ id: id, body: body });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Typography variant="h5">Weeks</Typography>
        <AddChapter size="small" />
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box overflow="auto" height="80vh">
        {/* Check if items is defined and not empty before rendering */}
        {items && items.length > 0 && (
          <Reorder.Group axis="y" values={items} onReorder={handleReorder}>
            {items.map((item: any, index: number) => (
              <Reorder.Item
                style={{
                  borderRadius: "10px",
                  margin: "5px",
                  listStyle: "none",
                }}
                key={item._id}
                value={item}
              >
                <ChapterCard data={item} key={index} />
              </Reorder.Item>
            ))}
          </Reorder.Group>
        )}
      </Box>
    </Paper>
  );
};

const AddChapter = ({ size = "small" }: { size: "small" | "medium" }) => {
  const [openNewChapter, setOpenNewChapter] = useState<boolean>(false);
  return (
    <div>
      <Button
        color="secondary"
        variant="outlined"
        size={size}
        onClick={() => setOpenNewChapter(true)}
      >
        Add Week
      </Button>
      <DialogWrapper
        title="Add Week"
        open={openNewChapter}
        setOpen={setOpenNewChapter}
      >
        <CreateChapter data={null} close={() => setOpenNewChapter(false)} />
      </DialogWrapper>
    </div>
  );
};

const ChapterCard = ({ data }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useSearchParams();
  const chapterId = search.get("chapterId");
  const isActive = search.get("chapterId") == data?._id;

  useEffect(() => {
    if (isActive) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [chapterId]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setSearch({ chapterId: data?._id });
    setOpen((prev) => !prev);
  };

  const moment = require("moment");

  function formatTime(hours: any, minutes: any, seconds: any) {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      return "N/A";
    } else if (hours === 0) {
      return moment({ minutes, seconds }).format("mm:ss");
    } else {
      return moment({ hours, minutes, seconds }).format("HH:mm:ss");
    }
  }

  const durationInSeconds = data?.duration;
  const duration = moment.duration(durationInSeconds, "seconds");
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  <Typography>{`${formatTime(hours, minutes, seconds)}`}</Typography>;

  return (
    <>
      <List sx={{ py: 0.5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ListItemButton
            onDrag={() => {
              setOpen(false);
            }}
            onClick={handleClick}
            selected={isActive}
          >
            <ListItemText primary={data?.title} />
            <Typography>{`${formatTime(hours, minutes, seconds)}`}</Typography>
            <ListItemIcon>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
            <ChapterActions chapter={data} />
          </ListItemButton>
        </Box>
      </List>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            pl: 1,
          }}
        >
          <Box>
            {data?.sections?.map((item: any, index: number) => (
              <SectionCard section={item} chapterId={data?._id} key={index} />
            ))}
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

const SectionCard = ({ section, chapterId }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useSearchParams();
  const sectionId = search.get("sectionId");
  const isActive = search.get("sectionId") == section?._id;

  useEffect(() => {
    if (isActive) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [sectionId]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setSearch({ chapterId, sectionId: section?._id });
    setOpen((prev) => !prev);
  };

  const moment = require("moment");

  function formatTime(hours: any, minutes: any, seconds: any) {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      return "N/A";
    } else if (hours === 0) {
      return moment({ minutes, seconds }).format("mm:ss");
    } else {
      return moment({ hours, minutes, seconds }).format("HH:mm:ss");
    }
  }

  const durationInSeconds = section?.duration;
  const duration = moment.duration(durationInSeconds, "seconds");
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return (
    <>
      <List sx={{ py: 0.5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ListItemButton onClick={handleClick} selected={isActive}>
            <ListItemText primary={section?.title} />
            <Typography>{`${formatTime(hours, minutes, seconds)}`}</Typography>
            <ListItemIcon>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
            <SectionActions section={section} />
          </ListItemButton>
        </Box>
      </List>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            pl: 1,
          }}
        >
          <Box>
            {section?.topics?.map((item: any, index: number) => (
              <TopicCard
                topic={item}
                sectionId={section?._id}
                key={index}
                chapterId={chapterId}
              />
            ))}
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

const TopicCard = ({ topic, sectionId, chapterId }: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topicId = searchParams.get("topicId");
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { mutate: deletetopic } = useMutation(DeleteTopic, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("subjectById");
      toast.success("Topic deleted successfully");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleClickOptions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpenModal(true);
  };

  const handleDeletetopic = () => {
    deletetopic(topicId);
  };

  const handleAddSection = () => {
    setOpenModal(true);
  };

  const theme = useTheme();
  const [search, setSearch] = useSearchParams();
  const isTopicActive = search.get("topicId") == topic?._id;

  const handleClick = () => {
    setSearch({ chapterId, sectionId, topicId: topic?._id });
  };

  const moment = require("moment");

  function formatTime(hours: any, minutes: any, seconds: any) {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      return "N/A";
    } else if (hours === 0) {
      return moment({ minutes, seconds }).format("mm:ss");
    } else {
      return moment({ hours, minutes, seconds }).format("HH:mm:ss");
    }
  }

  const durationInSeconds = topic?.duration;
  console.log("durationInSeconds", durationInSeconds);
  const duration = moment.duration(durationInSeconds, "seconds");
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return (
    <ListItemButton
      onClick={handleClick}
      disableRipple
      sx={{
        fontWeight: "bold",
      }}
    >
      <Box display="flex" width="100%" gap={2} alignItems="center">
        {isTopicActive ? (
          <Badge variant="dot" color="primary" />
        ) : (
          <Badge variant="dot" />
        )}
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            color={isTopicActive ? theme.palette.primary.main : "#000"}
          >
            {topic?.title}
          </Typography>
          <Typography>
            {/* <Typography>{`${formatTime(hours, minutes, seconds)}`}</Typography> */}
            <Typography>
              {moment
                .duration(durationInSeconds, "seconds")
                .format("hh:mm:ss", {
                  trim: false,
                })}
            </Typography>
          </Typography>
          <IconButton onClick={handleClickOptions} size="small">
            <MoreVert fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <Edit color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Topic" />
        </MenuItem>
        <MenuItem onClick={handleDeletetopic}>
          <ListItemIcon>
            <Delete color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete Topic" />
        </MenuItem>
      </Menu>
      <DialogWrapper
        title="Create Topic"
        open={openModal}
        setOpen={setOpenModal}
      >
        <TopicForm
          close={() => setOpenModal(false)}
          section={topic}
          chapterId={chapterId}
        />
      </DialogWrapper>
    </ListItemButton>
  );
};

const ChapterActions = ({ chapter: data }: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openChapterModal, setOpenChapterModal] = useState<boolean>(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpenChapterModal(true);
  };

  const handleDelete = () => {};

  const handleAddSection = () => {
    setOpenModal(true);
  };
  return (
    <div>
      <IconButton onClick={handleClick} color="secondary" size="small">
        <MoreVertOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleAddSection}>
          <ListItemIcon>
            <Add color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Add Section" />
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <Edit color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Chapter" />
        </MenuItem>
        {/* <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <Delete color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete Chapter" />
        </MenuItem> */}
      </Menu>
      <DialogWrapper
        title="Create Section"
        open={openModal}
        setOpen={setOpenModal}
      >
        <SectionForm
          close={() => setOpenModal(false)}
          section={null}
          chapterId={data?.id}
        />
      </DialogWrapper>
      <DialogWrapper
        title="Edit Chapter"
        open={openChapterModal}
        setOpen={setOpenChapterModal}
      >
        <CreateChapter data={data} close={() => setOpenChapterModal(false)} />
      </DialogWrapper>
    </div>
  );
};

const SectionActions = ({ section: data }: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSectionModal, setOpenSectionModal] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpenSectionModal(true);
  };

  const handleDelete = () => {};

  const handleAddTopic = () => {
    setOpenModal(true);
  };

  return (
    <div>
      <IconButton onClick={handleClick} color="secondary" size="small">
        <MoreVertOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleAddTopic}>
          <ListItemIcon>
            <Add color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Add Topic" />
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <Edit color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Section" />
        </MenuItem>
        {/* <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <Delete color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete Section" />
        </MenuItem> */}
      </Menu>
      <DialogWrapper
        title="Create Topic"
        open={openModal}
        setOpen={setOpenModal}
      >
        <TopicForm
          close={() => setOpenModal(false)}
          section={null}
          chapterId={data?.id}
        />
      </DialogWrapper>
      <DialogWrapper
        title="Edit Section"
        open={openSectionModal}
        setOpen={setOpenSectionModal}
      >
        <SectionForm section={data} close={() => setOpenSectionModal(false)} />
      </DialogWrapper>
    </div>
  );
};
