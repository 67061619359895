import {
  Add,
  AddLinkOutlined,
  ImageOutlined,
  OndemandVideo,
  TextFields,
  ViewInArOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Fab,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import DialogWrapper from "../../../../components/DialogWrapper/DialogWrapper";
import { EBookComponent } from "./forms/Ebookscomponents";
import FileContentForm from "./forms/FileComponent";
import ImageContentForm from "./forms/ImageComponent";
import LinkContentForm from "./forms/LinkComponent";
import QuizComponent from "./forms/QuizComponent";
import VideoContentForm from "./forms/VideoComponent";
import Visualform from "./forms/Visualform";
import { ExperimentForm } from "./forms/experimentForm";
import TextContentForm from "./forms/textContentForm";

const AddContentButton = ({ components }: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search] = useSearchParams();
  const topicId = search.get("topicId");
  const open = Boolean(anchorEl);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [contentType, setContentType] = useState<{
    title: string;
    key: string;
  }>({
    title: "Text",
    key: "text",
  });

  const handleToggle = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenModal = (type: any) => {
    setOpenModal(true);
    setContentType(type);
  };

  const renderContentForm = () => {
    switch (contentType.key) {
      case "text":
        return (
          <TextContentForm data={null} close={() => setOpenModal(false)} />
        );
      case "image":
        return (
          <ImageContentForm data={null} close={() => setOpenModal(false)} />
        );
      case "video":
        return (
          <VideoContentForm data={null} close={() => setOpenModal(false)} />
        );
      case "visual":
        return (
          <>
            <Visualform
              data={null}
              close={() => setOpenModal(false)}
            ></Visualform>
          </>
        );
      case "quiz":
        return (
          <QuizComponent
            data={components?.filter((item: any) => item.type === "QUIZ")}
            close={() => setOpenModal(false)}
          />
        );
      case "ebook":
        return (
          <EBookComponent
            data={components?.filter((item: any) => item.type === "EBOOK")}
            close={() => setOpenModal(false)}
          />
        );
      case "link":
        return (
          <LinkContentForm data={null} close={() => setOpenModal(false)} />
        );
      case "resource":
        return (
          <FileContentForm data={null} close={() => setOpenModal(false)} />
        );
      case "experiment":
        return <ExperimentForm data={null} close={() => setOpenModal(false)} />;
      default:
        return <div>No Match</div>;
    }
  };

  return (
    <div>
      {topicId && (
        <Tooltip title="Add Content" sx={{ p: 1 }}>
          <Fab
            onClick={handleToggle}
            sx={{
              position: "fixed",
              bottom: "50px",
              right: "50px",
              width: "80px",
              height: "80px",
            }}
            color="primary"
            aria-label="add"
          >
            <Add fontSize="large" />
          </Fab>
        </Tooltip>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {optionsMenu.map((item, index, arr) => (
          <Box key={index}>
            {/* <MenuItem>{item.title}</MenuItem> */}
            <ListItem
              style={{ minWidth: "180px" }}
              onClick={() => handleOpenModal(item)}
              key={item.key}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>

            {index + 1 < arr.length ? <Divider /> : null}
          </Box>
        ))}
      </Menu>
      <DialogWrapper
        width="lg"
        title={`${contentType.title} Component`}
        open={openModal}
        setOpen={setOpenModal}
      >
        {renderContentForm()}
      </DialogWrapper>
    </div>
  );
};

export default AddContentButton;

const optionsMenu = [
  {
    title: "Video",
    key: "video",
    icon: <OndemandVideo />,
  },
  {
    title: "Image",
    key: "image",
    icon: <ImageOutlined />,
  },
  {
    title: "Text",
    key: "text",
    icon: <TextFields />,
  },
  // {
  //   title: "Quiz",
  //   key: "quiz",
  //   icon: <QuizOutlined />,
  // },
  // {
  //   title: "Resource",
  //   key: "resource",
  //   icon: <AttachFileOutlined />,
  // },
  {
    title: "Link",
    key: "link",
    icon: <AddLinkOutlined />,
  },
  // {
  //   title: "E Book",
  //   key: "ebook",
  //   icon: <MenuBookOutlined />,
  // },
  {
    title: "3D Visualization",
    key: "visual",
    icon: <ViewInArOutlined />,
  },
  {
    title: "Experiment",
    key: "experiment",
    icon: <ViewInArOutlined />,
  },
];
