import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Avatar,
  Box,
  Button,
  Divider,
  styled,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOneDiscussionForum, reply } from "../../../api/services";
import { Loader } from "../../../components/loader";
import Quill from "../../../components/Quill";
import { capitalize } from "../../../utils";

const Discussion = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["getOneDiscussionForum", id],
    getOneDiscussionForum
  );

  const { mutate } = useMutation(reply, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("getOneDiscussionForum");
      toast.success(res?.data?.message);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleSubmit = () => {
    const postData = { id: data?.data?._id, reply: comment };
    mutate(postData);
    setOpen(false);
    setComment("");
  };

  return (
    <Box>
      <Button
        startIcon={<ChevronLeftIcon />}
        disableRipple
        sx={{ ":hover": { backgroundColor: "transparent" } }}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              pb: 2,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                  pb: "5px",
                }}
              >
                <Avatar
                  sx={{
                    height: "35px",
                    width: "35px",
                    fontSize: "16px",
                    backgroundColor: "#f4347f",
                  }}
                >
                  {data?.data?.user?.name?.[0]}
                </Avatar>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "17px",
                      fontFamily: "figtree_semibold",
                      py: 0,
                    }}
                  >
                    {capitalize(data?.data?.user?.name)}
                  </Typography>
                  <Typography variant="caption">{`Posted on ${moment(
                    data?.data?.createdAt
                  ).format("DD MMM, YYYY")}`}</Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  alignContent: "center",
                  fontWeight: 900,
                  textDecoration: "none",
                  color: "black",
                  pb: "3px",
                }}
              >
                {data?.data?.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{ "& *": { p: 0, m: 0 } ,fontSize:"13px"}}
                dangerouslySetInnerHTML={{ __html: data?.data?.description }}
              ></Typography>
              {!open && (
                <Button
                  variant="text"
                  sx={{ p: 0, m: 0, width: "max-content", fontSize: "13px" }}
                  disableRipple
                  onClick={() => setOpen(true)}
                >
                  Leave a comment
                </Button>
              )}
              {open && (
                <Box sx={{ pt: 2 }}>
                  <Quill
                    placeholder="Leave you comment"
                    data={comment}
                    handleChange={(value: any) => setComment(value)}
                  />
                  <Button
                    variant="contained"
                    sx={{ width: "150px" }}
                    onClick={handleSubmit}
                  >
                    Comment
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          {data?.data?.reply?.length > 0 && <Divider />}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, py: 2,pl:6 }}>
            {data?.data?.reply?.map((item: any, index: any) => (
              <>
                <Comment key={index} data={item} />
                {index + 1 !== data?.data?.reply?.length && <Divider />}
              </>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Discussion;

const StyledFlexBox = styled("div")(() => ({
  display: "flex",
}));

const Comment = ({ data }: any) => {
  console.log("data", data);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          pb: "5px",
        }}
      >
        {data?.imageUrl ? (
          <Avatar
            alt={data?.user?.name}
            src={data?.imageUrl}
            sx={{
              height: "35px",
              width: "35px",
              fontSize: "16px",
              backgroundColor: "#f4347f",
            }}
          />
        ) : (
          <Avatar
            sx={{
              height: "35px",
              width: "35px",
              fontSize: "16px",
              backgroundColor: "#f4347f",
            }}
          >
            {data?.user?.name[0]}
          </Avatar>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "17px",
              fontFamily: "figtree_semibold",
              py: 0,
            }}
          >
            {data?.user?.name}
          </Typography>
          <Typography variant="caption">{`Posted on ${moment(
            data?.createdAt
          ).format("DD MMM, YYYY")}`}</Typography>
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{ "& *": { p: 0, m: 0 } }}
        dangerouslySetInnerHTML={{ __html: data?.reply }}
      ></Typography>
    </Box>
  );
};
