import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SidePanel } from "./sidePanel";
import Topic from "./topic";
const CurriculumBuilder = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          sx={{ width: "10%" }}
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{ width: "10%" }}
          onClick={() => navigate("e-books")}
        >
          E Books
        </Button>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <SidePanel />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Topic />
        </Box>
      </Box>
    </Box>
  );
};

export default CurriculumBuilder;
