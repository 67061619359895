import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useImmer } from "use-immer";
import * as yup from "yup";
import {
  createStudent,
  getAllCenterType,
  getAllDistrics,
  getAllItiCenters,
  getAllLanguages,
  getAllStudents,
  getAllTrades,
  updateStudentsStatus,
} from "../../api/services";
import { emptyDashboard } from "../../assets";
import DialogWrapper from "../../components/DialogWrapper/DialogWrapper";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../components/DrawerTemplate";
import EmptyPage from "../../components/EmptyPage";
import { FileUpload } from "../../components/FileUpload";
import { ImageUpload } from "../../components/ImageUpload";
import EditImage from "../../components/ImageUpload/EditImage";
import { Loader } from "../../components/loader";
import Maxheightmenu_C from "../../components/Menu/Maxheightmenu_C";
import Table from "../../components/TableComponent";
import { StyledButton, StyledFlexBox } from "../../styles";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .min(10, "Mobile number should be at least 10 digits")
    .max(10, "Mobile number should not exceed 10 digits")
    .matches(/^[0-9]+$/, "Must be a Number"),
  email: yup
    .string()
    .required("Email is required")
    .email("Email is invalid")
    .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "Email is invalid"),
  fatherName: yup.string().required("Father Name is required"),
  aadharNumber: yup
    .string()
    .required("Aadhar number is required")
    .min(12, "Aadhar number should be at least 12 digits")
    .max(12, "Aadhar number should not exceed 10 digits")
    .matches(/^[0-9]+$/, "Must be a Number"),
  dateOfBirth: yup.string().required("Date of birth is required"),
  districtId: yup.string().required("District is required"),
  tradeId: yup.string().required("Trade is required"),
  typeId: yup.string().required("Centre type is required"),
  centreId: yup.string().required("Centre is required"),
  languageIds: yup.array().min(1, "At least one language is required"),
});

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function Students() {
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [Imagekey, SetImagekey] = useState(null);
  const [Aadharkey, SetAadharkey] = useState(null);
  const [ssckey, Setssckey] = useState(null);
  const [signkey, Setsignkey] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [studentId, setStudentId] = useState<any[]>([]);
  const [action, setAction] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const handleUpload = (v: any) => {
    if (v) {
      setModalOpen(false);
      navigate("/Students/import", {
        state: {
          data: v,
          // formData: formData,
        },
      });
    }
  };

  const [filters, setFilters] = useImmer({
    page: 1,
    search: "",
    tradeId: "",
    district: "",
    status: "",
    centreId: "",
  });

  const status = window.location.href.split("/");

  const {
    data: StudentData,
    isLoading,
    refetch,
  } = useQuery(
    [
      "studentdata",
      {
        search: filters.search,
        offset: "",
        limit: "",
        tradeId: filters.tradeId,
        districtId: filters.district,
        status: status?.[status?.length - 1]?.toUpperCase(),
        centreId: filters.centreId,
      },
    ],
    getAllStudents
  );

  const { data: distdata, isLoading: distLoading } = useQuery(
    "allDistricts",
    getAllDistrics
  );
  const Distdata = !distLoading ? distdata?.data : [];
  const { data: tradedata } = useQuery("alltrades", getAllTrades);
  const Tradedata = tradedata?.data;
  const { data: centertypedata } = useQuery("allcentertype", getAllCenterType);
  const Centertypedata = centertypedata?.data?.data;
  const { data: itiCenterData } = useQuery(["iti-centers"], getAllItiCenters);
  const Centersdata = itiCenterData?.data?.data;
  const { data: langdata } = useQuery(["languages"], getAllLanguages);
  const Langdata = langdata?.data?.data;

  const { mutate } = useMutation(createStudent, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("studentdata");
      toast.success(res?.data?.message);
      setOpen(false);
    },
    onError: (err: any) => {
      err?.response?.data?.message?.map((data: any) => toast.error(data));
    },
  });

  const { mutate: updateStudentStatus } = useMutation(updateStudentsStatus, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("studentdata");
      toast.success(res?.data?.message);
    },
    onError: (err: any) => {
      console.log(err);
      toast.error(err?.response?.data?.message[1]);
    },
  });

  useEffect(() => {
    setStudentId([]);
  }, [window.location.href]);

  useEffect(() => {
    if (
      !distLoading &&
      Tradedata &&
      Centertypedata &&
      Centersdata &&
      Langdata
    ) {
      setDataLoaded(true);
    }
  }, [distLoading, Tradedata, Centertypedata, Centersdata, Langdata]);

  if (!dataLoaded) {
    return <Loader />;
  }

  const handleCheckboxChange = (rowId: any) => {
    const updatedIds = studentId.includes(rowId)
      ? studentId.filter((id) => id !== rowId)
      : [...studentId, rowId];
    setStudentId(updatedIds);
  };

  const handleMasterCheckboxChange = (e: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allIds = StudentData?.data?.data.map((row: any) => row._id) || [];
      setStudentId(allIds);
    } else {
      setStudentId([]);
    }
  };

  const column = [
    {
      title: (
        <Box display="flex" alignItems="center">
          <Checkbox
            color="info"
            checked={
              studentId.length > 0 &&
              studentId.length === StudentData?.data?.data.length
            }
            onChange={(e: any) => {
              handleMasterCheckboxChange(e);
            }}
          />
        </Box>
      ),
      dataIndex: "selected",
      key: "selected",
      render: (coldata: any, row: any) => {
        return (
          <Checkbox
            checked={studentId.includes(row._id)}
            onChange={(e: any) => handleCheckboxChange(row._id)}
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (colData: any, row: any) => {
        const image = `${process.env.REACT_APP_FILE_URL}/${row.imageKey}`;
        return (
          <Box display={"flex"} width={"300px"}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                }}
              >
                {row.imageKey ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={image}
                    alt="img.."
                  />
                ) : (
                  <Avatar
                    variant="square"
                    sx={{ width: "100%", height: "100%" }}
                  />
                )}
              </Box>
              <Typography
                sx={{
                  marginLeft: "50px",
                }}
                variant="body1"
                color="initial"
              >
                {row.name}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Trade",
      dataIndex: "trade",
      key: "trade",
      render: (_: any, rowdata: any) => {
        return <Typography>{rowdata?.trade?.name}</Typography>;
      },
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      render: (_: any, rowdata: any) => {
        return <Typography>{rowdata?.year?.name}</Typography>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_: any, rowdata: any) => {
        return <Typography>{rowdata?.type?.name}</Typography>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      render: (_: any, rowdata: any) => {
        return <Typography>{rowdata?.district?.name}</Typography>;
      },
    },
    {
      title: "Actions",
      dataIndex: "_id",
      key: "_id",
      render: (colData: any, row: any) => {
        return (
          <Box>
            <Maxheightmenu_C
              isEdit={false}
              isDelete={false}
              onView={() => {
                navigate(`${row._id}`);
              }}
            />
          </Box>
        );
      },
    },
  ];

  const handleImageUpload = (value: any) => {
    if (!value) return;
    SetImagekey(value?.key);
  };

  const handleImageAadhar = (value: any) => {
    if (!value) return;
    SetAadharkey(value?.key);
  };

  const handleImageSsc = (value: any) => {
    if (!value) return;
    Setssckey(value?.key);
  };

  const handleImageSign = (value: any) => {
    if (!value) return;
    Setsignkey(value?.key);
  };

  const onSubmit = (data: any) => {
    const newdata = {
      ...data,
      imageKey: Imagekey,
      aadharKey: Aadharkey,
      sscKey: ssckey,
      signatureKey: signkey,
    };

    mutate(newdata);
  };

  const handleTradeChange = (event: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      tradeId: event.target.value as string,
    }));
  };

  const handleDistrictChange = (event: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      district: event.target.value as string,
    }));
  };

  const handleStatusChange = (event: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      status: event.target.value as string,
    }));
  };

  const handleCentreIdChange = (event: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      centreId: event.target.value as string,
    }));
  };

  const handleActionChange = (event: any) => {
    setAction(event.target.value);
    const newData = {
      ids: studentId,
      status: event.target.value,
    };
    updateStudentStatus(newData);
    setStudentId([]);
  };

  const handleReset = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: "",
      tradeId: "",
      district: "",
      status: "",
      centreId: "",
    }));
  };

  const uploads = [
    { name: "Image", handler: handleImageUpload, key: Imagekey },
    { name: "Aadhar", handler: handleImageAadhar, key: Aadharkey },
    { name: "SSC", handler: handleImageSsc, key: ssckey },
    { name: "Signature", handler: handleImageSign, key: signkey },
  ];

  const handleSuccess = () => {
    console.log("hello");
    navigate("Students/import");
  };

  return (
    <Box>
      <Stack gap={2} mb={3}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <TextField
            label="Search"
            sx={{ minWidth: "450px" }}
            value={filters.search}
            onChange={(e) =>
              setFilters((s: any) => {
                s.search = e.target.value;
              })
            }
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            {status?.[status?.length - 1] === "waiting" && (
              <StyledFlexBox gap={2}>
                <StyledButton
                  sx={{ width: "190px" }}
                  variant="contained"
                  onClick={handleModalOpen}
                >
                  Import Students
                </StyledButton>
              </StyledFlexBox>
            )}
            <StyledFlexBox gap={2}>
              <StyledButton
                sx={{ width: "190px" }}
                onClick={handleAdd}
                variant="contained"
              >
                + Add Students
              </StyledButton>
            </StyledFlexBox>
          </Box>
        </Stack>
        <Stack direction={"row"} width={"100%"} gap={2}>
          <TextField
            variant="outlined"
            label={"Trade"}
            select
            sx={{ width: "300px", borderRadius: "10px" }}
            value={filters.tradeId}
            onChange={handleTradeChange}
          >
            {tradedata?.data.map((eachData: any) => (
              <MenuItem key={eachData._id} value={eachData._id}>
                {eachData.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            label={"District"}
            select
            sx={{ width: "300px", borderRadius: "10px" }}
            value={filters.district}
            onChange={handleDistrictChange}
          >
            {Distdata?.data?.map((eachData: any) => (
              <MenuItem key={eachData?._id} value={eachData?._id}>
                {eachData?.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            label={"ITI Centre"}
            select
            sx={{ width: "300px", borderRadius: "10px" }}
            value={filters.centreId}
            onChange={handleCentreIdChange}
          >
            {Centersdata.map((eachData: any) => (
              <MenuItem key={eachData._id} value={eachData._id}>
                {eachData.name}
              </MenuItem>
            ))}
          </TextField>
          <Button
            sx={{
              width: "200px",
              ":hover": { backgroundColor: "transparent" },
            }}
            disableRipple
            onClick={handleReset}
          >
            Reset Filters
          </Button>
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ pb: 1 }}
      >
        <Typography>
          Selected {studentId.length} {studentId.length <= 1 ? "row" : "rows"}
        </Typography>
        {studentId.length > 0 ? (
          <FormControl>
            <InputLabel id="demo-simple-select-label">Action</InputLabel>
            <Select
              sx={{ width: "200px", borderRadius: "10px", marginBottom: "2px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={action}
              label="Action"
              onChange={handleActionChange}
            >
              <MenuItem value={"WAITING"}>WAITING</MenuItem>
              <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
              <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
            </Select>
          </FormControl>
        ) : (
          ""
        )}
      </Stack>

      {StudentData?.data?.count === 0 ? (
        <EmptyPage image={emptyDashboard} title="There are no Students" />
      ) : (
        <>
          <Table
            columns={column}
            headAlign={"center"}
            tableAlign={"center"}
            dataSource={StudentData?.data?.data}
            loading={isLoading}
            // pagination={{
            //   page: offset / limit,
            //   count: userData?.data?.count,
            //   onPageChange: handlePagination,
            //   rowsPerPage: limit,
            //   onRowsPerPageChange: handlePaginationLimit,
            // }}
          />
        </>
      )}

      <Drawer
        PaperProps={{
          sx: {
            width: "500px",
          },
        }}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <StyledDrawerHeader>
          <Typography color="#FFFFFF" variant="h5">
            Add Student
          </Typography>
          <Typography
            variant="h6"
            color="#FFFFFF"
            sx={{
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormLabel>Name</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Enter Name"
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                      />
                    </>
                  );
                }}
              ></Controller>
              <Controller
                name="mobile"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormLabel>Mobile Number</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Enter Mobile Number"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    </>
                  );
                }}
              ></Controller>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormLabel>Email Id</FormLabel>
                      <TextField
                        type="email"
                        {...field}
                        placeholder="Enter Email Id"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    </>
                  );
                }}
              ></Controller>
              <Controller
                name="fatherName"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormLabel>Father Name</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Enter Father Name"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    </>
                  );
                }}
              ></Controller>
              <Controller
                name="aadharNumber"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormLabel>Aadhar Number</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Enter Aadhar Number"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    </>
                  );
                }}
              ></Controller>
              <FormControl fullWidth>
                <FormLabel>
                  Date of Birth <span style={{ color: "#F4347F" }}>*</span>
                </FormLabel>
                <Controller
                  name="dateOfBirth" // Use the appropriate name for the dateOfBirth field
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      {/* <TextField type="date"  {...field} error={!!fieldState.error} helperText={fieldState.error?.message}/> */}

                      <DesktopDatePicker
                        {...field}
                        sx={{ width: "100%" }}
                        format="YYYY/MM/DD"
                        // maxDate={new Date()}
                      />
                      {fieldState?.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    my: "10px",
                  }}
                >
                  District
                </FormLabel>
                <Controller
                  name="districtId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        {...field}
                        error={!!fieldState?.error}
                        displayEmpty
                        renderValue={(selected) =>
                          selected ? (
                            Distdata?.data?.find(
                              (option: any) => option?._id === selected
                            )?.name || ""
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "10",
                                color: "grey",
                              }}
                            >
                              Select District
                            </Typography>
                          )
                        }
                      >
                        {Distdata?.data?.map((option: any) => (
                          <MenuItem key={option?._id} value={option?._id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState?.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    my: "10px",
                  }}
                >
                  Trade
                </FormLabel>
                <Controller
                  name="tradeId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        {...field}
                        error={!!fieldState?.error}
                        displayEmpty
                        renderValue={(selected) =>
                          selected ? (
                            Tradedata.find(
                              (option: any) => option._id === selected
                            )?.name || ""
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "10",
                                color: "grey",
                              }}
                            >
                              Select Trade
                            </Typography>
                          )
                        }
                      >
                        {Tradedata.map((option: any) => (
                          <MenuItem key={option._id} value={option?._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState?.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    my: "10px",
                  }}
                >
                  Centre Type
                </FormLabel>
                <Controller
                  name="typeId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        {...field}
                        error={!!fieldState?.error}
                        displayEmpty
                        renderValue={(selected) =>
                          selected ? (
                            Centertypedata.find(
                              (option: any) => option._id === selected
                            )?.name || ""
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "10",
                                color: "grey",
                              }}
                            >
                              Select Center Type
                            </Typography>
                          )
                        }
                      >
                        {Centertypedata.map((option: any) => (
                          <MenuItem key={option._id} value={option?._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState?.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    my: "10px",
                  }}
                >
                  Center
                </FormLabel>
                <Controller
                  name="centreId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        {...field}
                        error={!!fieldState?.error}
                        displayEmpty
                        renderValue={(selected) =>
                          selected ? (
                            Centersdata.find(
                              (option: any) => option._id === selected
                            )?.name || ""
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "10",
                                color: "grey",
                              }}
                            >
                              Select Center
                            </Typography>
                          )
                        }
                      >
                        {Centersdata.map((option: any) => (
                          <MenuItem key={option._id} value={option?._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState?.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>

              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    my: "10px",
                  }}
                >
                  Select Language
                </FormLabel>
                <Controller
                  name="languageIds"
                  control={control}
                  defaultValue={[]}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        {...field}
                        multiple
                        error={!!fieldState?.error}
                        displayEmpty
                        renderValue={(selected) =>
                          selected.length > 0 ? (
                            selected
                              .map(
                                (selectedValue) =>
                                  Langdata.find(
                                    (option: any) =>
                                      option._id === selectedValue
                                  )?.name || ""
                              )
                              .join(", ")
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "10",
                                color: "grey",
                              }}
                            >
                              Select Language
                            </Typography>
                          )
                        }
                      >
                        {Langdata.map((option: any) => (
                          <MenuItem key={option._id} value={option?._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState?.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>

              {uploads.map((item: any) => {
                return (
                  <>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FormLabel>
                        <Typography
                          sx={{
                            my: "3px",
                          }}
                          variant="h6"
                        >
                          Upload {item?.name}
                        </Typography>
                      </FormLabel>

                      <Box>
                        {item?.key != null ? (
                          <EditImage
                            imageUrl={`${process.env.REACT_APP_FILE_URL}/${item?.key}`}
                            onChange={item?.function}
                            uploadUrl={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
                            type="TRADE"
                            subtype="DOCS"
                          />
                        ) : (
                          <ImageUpload
                            url={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
                            onChange={item?.handler}
                            type="TRADE"
                            subtype="DOCS"
                          />
                        )}
                        <ToastContainer />
                      </Box>
                    </FormControl>
                  </>
                );
              })}

              <Button sx={{ mt: 2 }} type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </StyledDrawerContent>
      </Drawer>
      {/* <ConfirmModal
        prompt="Are you sure you want to delete this course?"
        // isOpen={OpenDelete}
        // proceed={deleterow}
        // cancel={() => setOpenDelete(false)}
      /> */}

      <DialogWrapper
        width="md"
        title={`Import Students`}
        open={modalOpen}
        setOpen={setModalOpen}
      >
        <FileUpload
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          url={`${process.env.REACT_APP_API_URL}/students/import`}
          onChange={handleUpload}
          type=""
          subtype=""
          onSuccess={handleSuccess}
          // questionBankId={params?.id}
        />
      </DialogWrapper>
    </Box>
  );
}
