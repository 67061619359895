import loadable from "@loadable/component";

export const Login = loadable(() => import("../pages/login"));
export const Curriculums = loadable(() => import("../pages/curriculums"));
export const CurriculumEbooks = loadable(
  () => import("../pages/curriculums/CurriculumEbooks")
);
export const Districts = loadable(
  () => import("../pages/masterData/districts")
);
export const ItiCenter = loadable(
  () => import("../pages/masterData/itiCenter")
);
export const Trade = loadable(() => import("../pages/masterData/Trade/trade"));
export const TradeView = loadable(
  () => import("../pages/masterData/Trade/TradeView")
);
export const ItiCenterType = loadable(
  () => import("../pages/masterData/itiCenterType")
);
export const Roles = loadable(() => import("../pages/masterData/roles"));
export const ExamTimetable = loadable(
  () => import("../pages/masterData/ExamTimetable")
);
export const Doubts = loadable(
  () => import("../pages/masterData/Doubts/Doubts")
);
export const Noticeboard = loadable(
  () => import("../pages/masterData/Noticeboard")
);
export const AddNoticeboard = loadable(
  () => import("../pages/masterData/NoticeboardForm")
);
export const Students = loadable(() => import("../pages/masterData/Students"));
export const Users = loadable(() => import("../pages/masterData/users"));
export const YearPage = loadable(() => import("../pages/masterData/year"));
export const Courses = loadable(() => import("../pages/courses/allCourses"));
export const AboutCourse = loadable(
  () => import("../pages/courses/CourseView/aboutCourse")
);
export const CourseExams = loadable(
  () => import("../pages/courses/CourseView/CourseExams")
);
export const CourseEbooks = loadable(
  () => import("../pages/courses/CourseView/CourseEbooks")
);
export const CourseConfig = loadable(
  () => import("../pages/courses/CourseView/courseConfig")
);
export const CoursesBundle = loadable(
  () => import("../pages/courses/courseBundles")
);
export const AboutCourseBundle = loadable(
  () => import("../pages/courses/courseBundles/Configuration/aboutBundle")
);
export const CourseBundleView = loadable(
  () => import("../pages/courses/courseBundles/Configuration/CourseBundleView")
);
export const Questions = loadable(
  () => import("../pages/question-bank/Questions")
);
export const Questionbank = loadable(() => import("../pages/question-bank"));
export const TradeTimeTable = loadable(
  () => import("../pages/StudentsCorner/TradeTimeTable")
);

export const TimeTableTabs = loadable(
  () => import("../pages/StudentsCorner/TradeTimeTable/Tabs/Tabs")
);
export const CourseBundleCourses = loadable(
  () =>
    import("../pages/courses/courseBundles/Configuration/CourseBundleCourses")
);

export const CourseBundleExams = loadable(
  () => import("../pages/courses/courseBundles/Configuration/CourseBundleExams")
);
export const ExaminationGuideline = loadable(
  () => import("../pages/StudentsCorner/ExaminationGuideline")
);

export const LiveSession = loadable(
  () => import("../pages/StudentsCorner/LiveSession")
);
export const DiscussionForum = loadable(
  () => import("../pages/StudentsCorner/DiscussionForum")
);
export const ExaminationGuidelineTabs = loadable(
  () =>
    import(
      "../pages/StudentsCorner/ExaminationGuideline/ExaminationGuidelineTabs"
    )
);
export const Quiz = loadable(() => import("../pages/quiz"));

export const QuizDetails = loadable(
  () => import("../pages/quiz/Configuration/QuizDetails")
);

export const Exam = loadable(() => import("../pages/exams"));

export const ExamDetails = loadable(
  () => import("../pages/exams/ExamConfiguration")
);

export const BundleQuiz = loadable(() => import("../pages/masterData/bundle"));

export const EBooks = loadable(() => import("../pages/e-books/EBooksList"));

export const EBooksDetails = loadable(
  () => import("../pages/e-books/EBookView")
);

export const StudyMaterials = loadable(
  () => import("../pages/study-materials/StudyMaterialList")
);

export const StudyMaterialDetails = loadable(
  () => import("../pages/study-materials/StudyMaterialView")
);

export const Assignments = loadable(
  () => import("../pages/assignments/AssignmentList")
);

export const AssignmentDetails = loadable(
  () => import("../pages/assignments/AssignmentView")
);

export const Resources = loadable(
  () => import("../pages/resources/ResourceList")
);

export const ResourceDetails = loadable(
  () => import("../pages/resources/ResourceView")
);

export const StudentsImport = loadable(
  () => import("../pages/masterData/StudentImport")
);
