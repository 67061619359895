import { Box, Button, FormControl, FormLabel, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import * as Yup from "yup";
import {
  createEbooks,
  getAllLanguages,
  getAllSubjects,
  getBundlesByTrade,
  getTrades,
  updateEbooks,
} from "../../api/services";
import SingleSelect from "../../components/forms/SingleSelect";
import { TextField } from "../../components/forms/TextField";
import { EditImage, ImageUpload } from "../../components/ImageUpload";
import Quill from "../../components/Quill";

const validationSchema = Yup.object().shape({
  title: Yup.string().max(255).required("The title field is required"),
  link: Yup.string().required("The code field is required"),
});

export default function EBookForm({ data, close }: any) {
  const queryClient = useQueryClient();

  const [state, setState] = useImmer({
    mediaObject: data?.fileKey ?? null,
    description: data?.description ?? "",
  });

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      title: data?.title ?? "",
      tradeId: data?.tradeId ?? "",
      yearId: data?.yearId ?? "",
      bundleId: data?.bundleId ?? "",
      languageId: data?.languageId ?? "",
      subjectId: data?.subjectId ?? "",
      link: data?.link ?? "",
    },
  });

  const { data: tradeData } = useQuery("trades", getTrades);

  const { data: languageData } = useQuery("languages", getAllLanguages);

  const { data: bundleYears } = useQuery(
    ["bundle-years", watch("tradeId")],
    getBundlesByTrade
  );

  const { data: subjectsData } = useQuery(["bundle-years"], getAllSubjects);

  const { mutate } = useMutation(createEbooks, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("e-books");
      toast.success(res?.data?.message);
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: updateMutate } = useMutation(updateEbooks, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("e-books");
      toast.success(res?.data?.message);
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  useEffect(() => {
    if (data?.tradeId !== watch("tradeId")) {
      setValue("bundleId", "");
      setValue("yearId", "");
    }
  }, [watch("tradeId")]);

  const trades = tradeData?.data?.data?.map((item: any) => ({
    value: item?._id,
    label: item?.name,
  }));

  const languages = languageData?.data?.data?.map((item: any) => ({
    value: item?._id,
    label: item?.name,
  }));

  const years = bundleYears?.data?.years?.map((item: any) => ({
    value: item?._id,
    label: item?.name,
  }));

  const bundles = bundleYears?.data?.bundles?.map((item: any) => ({
    value: item?._id,
    label: item?.name,
  }));

  const subjects = subjectsData?.data?.data?.map((i: any) => ({
    value: i?._id,
    label: i?.title,
  }));

  const onSubmit = (formData: any) => {
    console.log(formData);
    const postBody = {
      ...formData,
      file: state?.mediaObject?.key || data?.fileKey,
      description: state?.description,
    };
    if (data?._id) {
      updateMutate({ id: data?._id, body: postBody });
    } else {
      mutate(postBody);
    }
    console.log(postBody);
  };

  const handleImageUpload = (value: any) => {
    if (!value) return;

    setState((s) => {
      s.mediaObject = value;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <SingleSelect
          label="Trade"
          name="tradeId"
          control={control}
          options={trades}
        />
        <SingleSelect
          label="Bundle Type"
          name="bundleId"
          control={control}
          options={bundles}
        />

        <SingleSelect
          label="Year"
          name="yearId"
          control={control}
          options={years}
        />
        <SingleSelect
          label="Language"
          name="languageId"
          control={control}
          options={languages}
        />

        <SingleSelect
          label="Curriculum"
          name="subjectId"
          control={control}
          options={subjects}
        />
        <TextField name="title" label="E-Book Title" control={control} />

        <Box>
          {state?.mediaObject ? (
            <EditImage
              imageUrl={state.mediaObject?.s3Response?.url || data?.fileUrl}
              onChange={handleImageUpload}
              uploadUrl={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
              type="TRADE"
              subtype="DOCS"
            />
          ) : (
            <ImageUpload
              url={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
              onChange={handleImageUpload}
              type="TRADE"
              subtype="DOCS"
            />
          )}
        </Box>

        <TextField name="link" label="E-Book Link" control={control} />

        <FormControl>
          <FormLabel>Description</FormLabel>

          <Quill
            placeholder="Description"
            data={state?.description}
            handleChange={(value) => {
              setState((s) => {
                s.description = value;
              });
            }}
          />
        </FormControl>

        <Box>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
