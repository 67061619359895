export function capitalize(text: any) {
  return text
    ?.split(" ")
    ?.map(
      (string: string) =>
        string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    )
    ?.join(" ");
}

export function formatTime(seconds: any) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  let timeString = "";
  if (h > 0) {
    timeString += `${h}h `;
  }
  if (m > 0) {
    timeString += `${m}m `;
  }
  if (s > 0) {
    timeString += `${s}s`;
  }
  return timeString.trim();
}
